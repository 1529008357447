import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const DownloadPage = () => {
  const [loading, setLoading] = useState(true);

  const isIOS = () => {
    const userAgent = window.navigator.userAgent;
    return /iPad|iPhone|iPod/.test(userAgent);
  };

  const navigate = useNavigate();

  const playStoreLink =
    "https://play.google.com/store/apps/details?id=com.cherryco.gopool.android";
  const appStoreLink = "https://apps.apple.com/us/app/go-pool/id1661427392";

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get("source");
    const referral = params.get("referral");

    if (source || referral) {
      const eventProperties = {};
      if (source) eventProperties.source = source;
      if (referral) eventProperties.referral = referral;

      trackEvent(webEvents.DOWNLOAD_PAGE_LANDED, eventProperties);
      dispatchReactGAEvent({
        category: webEventsCategory.NAVIGATION,
        action: webEvents.DOWNLOAD_PAGE_LANDED,
        properties: eventProperties,
      });
    }

    const redirectUrl = isIOS() ? appStoreLink : playStoreLink;
    setLoading(false);
    window.location.href = redirectUrl;
  }, [navigate]);

  const ogData = {
    title: "Download App",
    description:
      "GoPool is your ultimate cabpooling and ridesharing app, offering safe, reliable, and cost-effective transportation solutions.",
    url: "https://gopool.app/Download",
    siteName: "GoPool",
    web: "website",
    image: "android-chrome-192x192.png",
  };

  return (
    <>
      <Helmet>
        <title>{ogData.title}</title>
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:site_name" content={ogData.siteName} />
        <meta property="og:type" content={ogData.web} />
        <meta name="image" property="og:image" content={ogData.image} />
        <meta name="description" content={ogData.description} />

        <meta name="twitter:title" content={ogData.title} />
        <meta name="twitter:description" content={ogData.description} />
        <meta name="twitter:url" content={ogData.url} />

        <link rel="canonical" href="https://gopool.app/Download" />
      </Helmet>
      {loading && (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
        </div>
      )}
    </>
  );
};

export default DownloadPage;
